import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";

import { Context } from "../stores/store";
import Config from "../stores/Config";
import Util from "../utilities/Util";

import {
  IconSpinner,
  IconClose,
  IconCopy,
  IconAngleLeft,
  IconAngleRight,
} from "../utilities/SvgIcon";

import Tags from "../features/Tags";

import mixpanel from "../utilities/MixPanel";

const LibraryDetails = ({
  details,
  isAuthenticated,
  isInitializing,
  onFilterChanged,
  onClose,
}) => {
  const [ContextState] = useContext(Context);
  const [videoError, setVideoError] = useState(false);
  const [videoReady, setVideoReady] = useState(false);

  const [createAppBusy, setCreateAppBusy] = useState(false);

  const lastItemId = useRef(-1);
  useEffect(
    (_) => {
      if (lastItemId.current !== details.item.id) {
        setVideoError(false);
        setVideoReady(false);
        mixpanel.track("View Library Item", {
          library_item_id: details.item.id,
        });
      }

      lastItemId.current = details.item.id;
    },
    [details]
  );

  const navigate = useNavigate();

  if (!details || !details.item) {
    return null;
  }

  // copy the app from the marketplace and handle the analytics
  const onCreateApp = (appId, marketplaceID, name, item) => {
    setCreateAppBusy(true);

    let url, body;
    if (isAuthenticated) {
      // use the last selected folder otherwise use the root folder
      let downloadFolder = "";
      if (ContextState.currentDownloadFolder) {
        downloadFolder = ContextState.currentDownloadFolder;
      } else {
        downloadFolder = ContextState.dashboardRootFolderOverlays;
      }

      // we download the market place item into my account through the singular API
      url =
        Config.singularUrl +
        "/apiv2/controlapps/marketplaceitem/" +
        appId +
        "/duplicate";
      body = JSON.stringify({
        folder: downloadFolder,
        marketplaceID: marketplaceID,
      });
    } else {
      // we download the marke place item into the try me account without authentication
      url = "/api/items/" + appId;
      body = JSON.stringify({
        marketplaceID: marketplaceID,
      });
    }

    fetch(url, {
      method: "POST",
      body: body,
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((data) => {
        setCreateAppBusy(false);
        if (data.error) {
          Util.addNotification("Error while creating overlay", "error");
        } else {
          // prepare the event to send to analytics
          let payload = {
            event: "Uno library download",
            data: {
              name: item.name,
              id: item.id,
            },
          };
          if (isAuthenticated) {
            //payload.data.distinct_id = ContextState.userInfo.accountId;
            payload.data.user = ContextState.userInfo.email;
          } else {
            //payload.data.distinct_id = "Try me";
            payload.data.user = "Try me";
          }
          if (item.featured) {
            payload.data.featured = item.featured;
          }
          if (item.tag_category) {
            payload.data.category = item.tag_category;
          }
          if (item.tag_datasource) {
            payload.data.datasource = item.tag_datasource;
          }
          if (item.tag_function) {
            payload.data.function = item.tag_function;
          }
          if (item.tag_layout) {
            payload.data.layout = item.tag_layout;
          }
          if (item.tag_theme) {
            payload.data.theme = item.tag_theme;
          }
          if (item.tag_type) {
            payload.data.type = item.tag_type;
          }

          mixpanel.track(payload.event, payload.data);

          if (isAuthenticated) {
            // tell the user that the overlay has been added to My Overlays
            Util.addNotification(
              name + " has been added to My Overlays",
              "success"
            );

            // show the overlays in the folder we download to or the root folder
            if (ContextState.currentDownloadFolder) {
              if (
                ContextState.currentDownloadFolder ===
                ContextState.dashboardRootFolderOverlays
              ) {
                navigate("/myoverlays/folders");
              } else {
                navigate("/myoverlays/" + ContextState.currentDownloadFolder);
              }
            } else {
              navigate("/myoverlays");
            }
          } else {
            // open the created app in a new tab
            Util.openUrl(data.sharedUrl);
          }
          // close the dialog
          onClose();
        }
      })
      .catch((error) => {
        setCreateAppBusy(false);
        Util.addNotification("Error while creating overlay", "error");
      });
  };

  const item = details.item;
  const previousUrl = details.previousUrl;
  const nextUrl = details.nextUrl;

  let firstContent = {};
  if (item && item.content && item.content.content && item.content.content.length) {
    firstContent = item.content.content[0];
  }

  // we need this in the create app function
  let marketplaceID = item.id;
  let appId = firstContent.element.id;

  const renderContent = (content) => {
    const renderImage = (overlay) => {
      return (
        <img
          className={"image" + (overlay ? " overlay" : "")}
          src={content.imageURL || ""}
          alt="thumbnail"
        />
      );
    };

    if (content.videoURL && !videoError) {
      return (
        <>
          <video
            key={content.videoURL}
            className="video"
            autoPlay
            muted
            loop
            playsInline
            onError={() => {
              setVideoError(true);
              setVideoReady(true);
            }}
            onLoadedData={() => setVideoReady(true)}
          >
            {Util.isIOS() === false && (
              <source src={content.videoURL} type="video/webm" />
            )}
            <source
              src={Util.addVideoUrlSuffix(content.videoURL, "", "mp4")}
              type="video/mp4"
            />
          </video>
          {!videoReady && (
            <>
              {renderImage(true)}
              <div className="videoLoading">
                <IconSpinner />
              </div>
            </>
          )}
        </>
      );
    } else {
      return renderImage(false);
    }
  };

  // the URL to share a deep link to this element
  let shareURL =
    window.location.origin + "/library/" + Util.getLibraryItemPath(item);

  // remove the hyperlinks in the description for now, because they are pointing to singular support
  let description = item.description;
  let start = "";
  while (start !== description) {
    start = description;
    description = start.replace(/<a\b[^>]*>/i, "").replace(/<\/a>/i, "");
  }

  let showBusy = false;
  if (
    createAppBusy ||
    isInitializing ||
    !ContextState.libraryContentLoaded ||
    ContextState.authenticationStatus === "busy" ||
    details.isDeepLink
  ) {
    showBusy = true;
  }

  return (
    <div className="content">
      <div className="librarydetails-header">
        <h1 className="title">{item.name}</h1>
        {Util.getLibraryNewReleaseDaysLeft(item) !== 0 && (
          <div className="new">New</div>
        )}
        <span
          className="close"
          onClick={() => {
            onClose();
            navigate("/library");
          }}
        >
          <IconClose />
        </span>
      </div>
      <Tags
        item={item}
        onSelectFilter={(e, type, value) => {
          onFilterChanged("toggle", type, value);
          onClose();
          navigate("/library");
        }}
      />

      <div className="visual">{renderContent(firstContent)}</div>

      { /* use h2 to make google search happy. it should be a div */ }
      <h2
        className="ql-editor ql-blank"
        data-gramm="false"
        dangerouslySetInnerHTML={{ __html: description }}
      ></h2>

      <div className="buttons">
        <CopyToClipboard
          options={{ format: "text/plain" }}
          text={shareURL}
          onCopy={() => {
            Util.addNotification("URL copied to clipboard");
          }}
          data-tip="Share overlay"
        >
          <button className="button">
            <IconCopy />
            Share Link
          </button>
        </CopyToClipboard>

        {!showBusy && (
          <button
            className="button-brand"
            onClick={() => onCreateApp(appId, marketplaceID, item.name, item)}
          >
            {!isAuthenticated && <span>Try Overlay</span>}
            {isAuthenticated && <span>Add to My Overlays</span>}
          </button>
        )}
        {showBusy && (
          <button className="button-brand">
            <IconSpinner />
          </button>
        )}
      </div>

      {previousUrl && (
        <div
          className="arrow left"
          onClick={() => {
            navigate(previousUrl);
          }}
        >
          <IconAngleLeft />
        </div>
      )}
      {nextUrl && (
        <div
          className="arrow right"
          onClick={() => {
            navigate(nextUrl);
          }}
        >
          <IconAngleRight />
        </div>
      )}
    </div>
  );
};

export default LibraryDetails;
