import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";

const initialState = {
  authenticationStatus: "",
  unoAccessToken: "",
  unoRefreshToken: "",
  userInfo: "",
  forceUserReload: 0,

  libraryContent: [],
  libraryContentLoaded: false,
  libraryNew: [],
  libraryNewLoaded: false,
  libraryPopular: [],
  libraryPopularLoaded: false,
  libraryTrending: [],
  libraryTrendingLoaded: false,
  librarySort: "changed91",

  dashboardView: "grid",
  dashboardSort: "changed91",

  dashboardRootFolderOverlays: null,
  dashboardFoldersOverlays: null,
  dashboardRootFolderImages: null,
  dashboardFoldersImages: null,

  dashboardOverlaysForceReload: 0,
  dashboardImagesForceReload: 0,
  dashboardInboxForceReload: 0,

  dashboardSelection: [],

  dashboardCreateFolder: null,

  currentDownloadFolder: null,
  imageDropped: [],

  theme: "dark",
  menuSize: "large",
  menuOverlayShow: true,
  menuOverlayFoldersShow: true,
  menuImageShow: true,
  menuImageFoldersShow: true,
  storageLoaded: false,

  subdomain: "",
  portalConfig: null,

  unoAds: null,
  unoAdsLoaded: false,

  globalNavigation: null,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
