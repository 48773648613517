import React, { useEffect, useState, useContext } from "react";
import { Context } from "../stores/store";
import { IconSpinner } from "../utilities/SvgIcon";

import Util from "../utilities/Util";

const ADVERTISEMENT_UPDATE_INTERVAL = 60000;

const Ads = ({ layout, onShowDialog }) => {
  const [ContextState, ContextDispatch] = useContext(Context);

  const [unoAdsRedraw, setUnoAdsRedraw] = useState(0);
  const [unoAds, setUnoAds] = useState([]);

  // initialize AdSense
  useEffect(() => {
    if (window) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    // display the ads in a random order
    const intervalHandler = setInterval(() => {
      setUnoAdsRedraw((prev) => prev + 1);
    }, ADVERTISEMENT_UPDATE_INTERVAL);
    return () => clearInterval(intervalHandler);
  }, []);

  useEffect(() => {
    if (
      ContextState.unoAdsLoaded === false ||
      !ContextState.unoAds ||
      !ContextState.unoAds.ads
    ) {
      setUnoAds([]);
      return;
    }

    const ads = [...ContextState.unoAds.ads];
    ads.sort(() => Math.random() - 0.5);
    setUnoAds(ads);
  }, [ContextState.unoAds, unoAdsRedraw, ContextState.unoAdsLoaded]);

  const renderUnoAds = () => {
    if (ContextState.unoAdsLoaded === false) {
      return (
        <div className="uno-good-stuff">
          <div className="loading">
            <IconSpinner />
          </div>
        </div>
      );
    }

    return (
      <div className="uno-good-stuff">
        {unoAds.map((ad, index) => {
          return (
            <div
              key={index}
              className={"uno-ad" + (ad.link ? " link" : "")}
              onClick={() => {
                if (ad.link) {
                  Util.openUrl(ad.link);
                }
              }}
            >
              <img className="image" src={ad.imageUrl} alt={ad.imageAlt} />
              {ad.title && <div className="title">{ad.title}</div>}
              {ad.message && <div className="message">{ad.message}</div>}
            </div>
          );
        })}
      </div>
    );
  };

  const renderRemoveAds = () => {
    // this will keep the space. If we are not authenticated
    // nothing will be rendered
    if (ContextState.authenticationStatus === "busy") {
      return <div className="remove-good-stuff"></div>;
    }

    return (
      <div className="remove-good-stuff">
        Want to enjoy an ad-free experience?
        <div
          className="link"
          onClick={() => {
            if (ContextState.authenticationStatus !== "ok") {
              onShowDialog("login");
            } else {
              ContextDispatch({
                type: "SET_GLOBAL_NAVIGATION",
                payload: "subscription",
              });
            }
          }}
        >
          Remove Ads
        </div>
      </div>
    );
  };

  // render the ads
  return (
    <div className={"good-stuff " + layout}>
      <div className="good-stuff-title">Sponsored</div>
      {renderUnoAds()}
      <ins
        key="adsbygoogle"
        className="adsbygoogle"
        style={{
          display: "inline-block",
          width: "300px",
          height: "250px",
          paddingRight: layout === "horizontal" ? "10px" : "0px",
          paddingTop: layout === "horizontal" ? "5px" : "0px",
        }}
        data-ad-client="ca-pub-9826658694170953"
        data-ad-slot="2889999909"
        data-ad-format="fixed"
        data-full-width-responsive="false"
      ></ins>
      {renderRemoveAds()}
    </div>
  );
};

export default Ads;
