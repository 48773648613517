import React, { useEffect } from "react";
import LibraryFilterSection from "./LibraryFilterSection";

import {
  IconFilterType,
  IconFilterCategory,
  IconFilterLayout,
  IconFilterTheme,
  IconFilterDataSource,
} from "../utilities/SvgIcon";

const LibraryFilter = ({ items, filter, onChanged, onReset, onSetAll }) => {
  // set the default filters if we find them in the URL
  useEffect(() => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      let result = {};
      for (const [key, value] of urlParams.entries()) {
        // the tag is always lower case
        let lowerKey = key.toLowerCase();

        // get the tags of this property
        let tags = getTagList(lowerKey);

        // split the comma separated value into an array and loop through it
        result[lowerKey] = [];
        for (const v of value.split(",")) {
          let vLower = v.toLowerCase();
          // loop over the tags and if we find the tag add it to our result
          for (const t of tags) {
            // compare in lower case
            let lowerT = t.toLowerCase();

            // check if string vLower is part of lowerT
            if (lowerT.indexOf(vLower) !== -1) {
              // but store the original value
              result[lowerKey].push(t);
            }
          }
        }
      }

      // if we have a result we set the filter
      if (Object.keys(result).length) {
        onSetAll(result);
      }
    }
  });

  // extract the data from the items
  const getTagList = (property) => {
    let result = [];
    let count = {};
    if (items && items.length) {
      items.map((item) => {
        if (item[property] && result.indexOf(item[property]) === -1) {
          result.push(item[property]);
        }
        if (item[property]) {
          if (count[item[property]]) {
            count[item[property]] += 1;
          } else {
            count[item[property]] = 1;
          }
        }
        return undefined;
      });
    }

    if (property === "tag_theme" || property === "tag_function") {
      // sort the result by the number of hits in the count
      result.sort((a, b) => {
        if (count[a] < count[b]) {
          return 1;
        }
        if (count[a] > count[b]) {
          return -1;
        }
        return a.localeCompare(b);
      });
    } else {
      result.sort();
    }
    return result;
  };

  // the built in search tags
  // check if we have an active filter and show the reset button
  let activeFilters = 0;
  for (const [, tags] of Object.entries(filter)) {
    if (tags.length) {
      activeFilters += tags.length;
    }
  }

  return (
    <div className="library-filter">
      <div className="filter-header">
        <span>Filters</span>
        {activeFilters !== 0 && (
          <span className="reset" onClick={onReset}>
            Clear ({activeFilters})
          </span>
        )}
      </div>

      <div className="content">
        <LibraryFilterSection
          icon={<IconFilterLayout />}
          title="Type"
          tags={getTagList("tag_type")}
          filter={filter["tag_type"]}
          onChanged={(sel) => onChanged("tag_type", sel)}
        />
        <LibraryFilterSection
          icon={<IconFilterType />}
          title="Function"
          tags={getTagList("tag_function")}
          filter={filter["tag_function"]}
          onChanged={(sel) => onChanged("tag_function", sel)}
        />
        <LibraryFilterSection
          icon={<IconFilterCategory />}
          title="Category"
          tags={getTagList("tag_category")}
          filter={filter["tag_category"]}
          onChanged={(sel) => onChanged("tag_category", sel)}
        />
        <LibraryFilterSection
          icon={<IconFilterTheme />}
          title="Theme"
          tags={getTagList("tag_theme")}
          filter={filter["tag_theme"]}
          onChanged={(sel) => onChanged("tag_theme", sel)}
        />
        {/*
        <LibraryFilterSection
          icon={<IconFilterLayout />}
          title="Layout"
          tags={getTagList("tag_layout")}
          filter={filter["tag_layout"]}
          onChanged={(sel) => onChanged("tag_layout", sel)}
        />
        */}
        <LibraryFilterSection
          icon={<IconFilterDataSource />}
          title="Data Source"
          tags={getTagList("tag_datasource")}
          filter={filter["tag_datasource"]}
          onChanged={(sel) => onChanged("tag_datasource", sel)}
        />
      </div>
    </div>
  );
};

export default LibraryFilter;
